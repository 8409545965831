import { BasePage } from "../../common/BasePage";
import { useNavigate } from "react-router-dom";
import { MyListOfEvents } from "./MyListOfEvents";

export const OrgPage = () => {
  const nav = useNavigate();
  const onCreateEvent = () => {
    nav("/create");
  };
  return (
    <BasePage>
      <div>
        <h3>Хотите организовать публичное событие?</h3>
        Добавьте свое событие и все пользователи приложения смогут его видеть! И
        это абсолютно бесплатно!
        <hr />
        <div className="d-flex justify-content-center ">
          <button
            onClick={onCreateEvent}
            className="btn my-event-button"
            style={{ fontSize: 24 + "px" }}
          >
            Создать
          </button>
        </div>
      </div>
      {/* <MyListOfEvents /> */}
    </BasePage>
  );
};
