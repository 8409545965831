import { makeAutoObservable } from "mobx";
import { DateButtons } from "../enums/DateButtons";
import { ITag } from "../interfaces/ITag";
import { DateHelper } from "../helpers/DateHelper";
import ITagWithRelated from "./Tags";

export class Settings {
  public dateFromSec: number = 0;
  public dateToSec: number = 0;
  public currentPage: number = 0;
  public chunkLength: number = 4;
  public hasMore: boolean = true;
  public showOnlyUnique: boolean = true;
  public currentDayFilter: DateButtons = DateButtons.today;
  public selectedTag: ITag = {} as ITag;
  public selectedListOfEvents: ITagWithRelated["related"] = [];

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * Получить временное интервал(от и до)
   * @param dayFilter
   */
  public static getTimeInterval(dayFilter: DateButtons): {
    startSec: number;
    finishSec: number;
  } {
    switch (dayFilter) {
      case DateButtons.today:
        const todaySec = Math.floor(Date.now() / 1000);
        return {
          startSec: todaySec,
          finishSec: todaySec,
        };
      case DateButtons.tomorrow:
        const tomorrowSec = Math.floor(Date.now() / 1000) + 24 * 60 * 60;
        return {
          startSec: tomorrowSec,
          finishSec: tomorrowSec,
        };
      case DateButtons.week:
        const { dateFromSec, dateToSec } = DateHelper.getWeekPeriodSec();

        return {
          startSec: dateFromSec,
          finishSec: dateToSec,
        };

      case DateButtons.all:
        return {
          startSec: 0,
          finishSec: 0,
        };
    }
  }
}
export const settingsGl = new Settings();
