import { observer } from "mobx-react-lite";

export const SearchBox = observer(() => {
  return (
    <div className="mt-2 mb-2 d-flex ">
      <input type="text" className="form-control" placeholder="Введите id" />
      <button className="btn btn-primary ms-1">Найти</button>
    </div>
  );
});
