import { ListOfEvents } from "./ListOfEvents";
import { BasePage } from "../../common/BasePage";
import { DatePanel } from "./DatePanel";
import { useTelegram } from "../../../hooks/useTelegram";
import { Telegram } from "@tg-app/types";
import { TagsPanel } from "./TagsPanel";
import { AdminService } from "../../../services/AdminService";
import { AdminPanel } from "../../common/AdminPanel";

// @ts-ignore
// const tg: Telegram.WebApp = window.Telegram.webApp;

export const ListPage = () => {
  const { user, tg, onToggleButton } = useTelegram();
  return (
    <BasePage>
      {AdminService.getService().isAdmin() && <AdminPanel />}
      <DatePanel />
      <TagsPanel />
      <ListOfEvents />
    </BasePage>
  );
};
