import { BasePage } from "../../common/BasePage";
import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { IFullEvent } from "../../../interfaces/IFullEvent";
import { storeContext } from "../../../store/root-store";
import { observer } from "mobx-react-lite";
import { QueryService } from "../../../services/QueryService";
import { StringHelper } from "../../../helpers/StringHelper";
import sanitizeHtml from "sanitize-html";
import ImageGallery from "react-image-gallery";
import { Loader } from "../../common/Loader";
import { AdminService } from "../../../services/AdminService";
import { DateManagePanel } from "./DateManagePanel";
import { CategoryManagmentPanel } from "./CategoryManagmentPanel";

enum listOfAdminComponents {
  date = "date",
  category = "category",
}

export const SingleEventPage = observer(() => {
  const { eventId } = useParams();
  const { singleStoreGL } = useContext(storeContext);
  const [isLoading, setIsLoading] = useState(true);
  const [currentAdminComponent, setAdminComponent] =
    useState<listOfAdminComponents>(listOfAdminComponents.date);

  const [fullEvent] = useState({} as IFullEvent);
  const loadEventInfo = async () => {
    if (eventId) {
      setIsLoading(true);
      const fullEvent = await singleStoreGL.loadSingleEvent(+eventId);
      singleStoreGL.fullEvent.description = StringHelper.addTgLabel(
        singleStoreGL.fullEvent.body
      );
      setIsLoading(false);
      console.log(singleStoreGL.fullEvent.description);
    }
  };
  useEffect(() => {
    loadEventInfo();
  }, []);
  return (
    <BasePage>
      {AdminService.getService().isAdmin() && <>{singleStoreGL.fullEvent.id}</>}
      {AdminService.getService().isAdmin() && (
        <>
          {currentAdminComponent === listOfAdminComponents.date && (
            <DateManagePanel key={eventId} eventId={+(eventId ?? 0)} />
          )}
          {currentAdminComponent === listOfAdminComponents.category && (
            <CategoryManagmentPanel eventId={+(eventId ?? 0)} />
          )}
        </>
      )}
      {!isLoading && (
        <div
          className="container"
          style={{ overflowY: "scroll", height: 100 + "%" }}
        >
          <div style={{ width: 100 + "%", maxHeight: 100 + "%" }}>
            <ImageGallery
              items={singleStoreGL.getImgForGallery(
                singleStoreGL.fullEvent.img_path
              )}
              showNav={false}
              showThumbnails={
                singleStoreGL.getImgForGallery(singleStoreGL.fullEvent.img_path)
                  .length > 1
              }
              useBrowserFullscreen={false}
              showPlayButton={false}
            />
          </div>
          <div className="row">
            <div className="col-12">
              {singleStoreGL.fullEvent?.post_id && (
                <a
                  href={`https://t.me/${singleStoreGL.fullEvent?.source_id
                    ?.split("_")
                    .filter((el, index, ar) => {
                      return index !== ar.length - 1;
                    })
                    .join("_")}/${singleStoreGL.fullEvent?.post_id}`}
                  target="blank"
                >
                  Источник
                </a>
              )}
              <p
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(singleStoreGL.fullEvent.body, {
                    allowedTags: ["b", "i", "em", "strong", "a"],
                    allowedAttributes: {
                      a: ["href"],
                    },
                  }),
                }}
                style={{ whiteSpace: "pre-line" }}
              ></p>
            </div>
          </div>
        </div>
      )}
      {isLoading && <Loader />}
    </BasePage>
  );
});
