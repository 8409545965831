import axios from "axios";
import { isDev, prodUrl, serverUrl } from "../env";
import { messagesStore } from "../store/Messages";
import { MessageTypes } from "../enums/MessageTypes";
import { ResponseStatuses } from "../enums/ResponseStatuses";
import { tg } from "../hooks/useTelegram";

export class QueryService {
  /**
   * POST запрос на сервер
   * @param url
   * @param data
   */
  public static async postQuery(url: string, data: {}) {
    try {
      const resp = await axios.post((isDev ? serverUrl : prodUrl) + url, {
        ...data,
        tgId: tg.initDataUnsafe.user?.id ?? 709420289,
      });
      /**
       * Обрабатываем ошибку от сервера. Причина неизвестна, необработанный exception
       */
      if (resp.data.type === ResponseStatuses.error) {
        messagesStore.add({
          text: resp.data.message,
          durationMs: 30000,
          type: MessageTypes.error,
        });
      }
      /**
       * Обрабатываем успешный запрос от сервера. Сообщение сформировано приложением
       */
      if (resp.data.type === ResponseStatuses.ok && resp.data.message) {
        messagesStore.add({
          text: resp.data.message,
          durationMs: 30000,
          type: MessageTypes.ok,
        });
      }

      return resp;
    } catch (e) {
      messagesStore.add({
        text: "Неизвестная ошибка, свяжитесь с администратором или попробуйте позже",
        durationMs: 30000,
        type: MessageTypes.error,
      });
    }
  }
  /**
   * Get запрос на сервер
   * @param url
   */
  public static async getQuery(url: string) {
    try {
      console.log(url)
      const resp = await axios.get((isDev ? serverUrl : prodUrl) + url);
      if (resp.data.type === ResponseStatuses.error) {
        messagesStore.add({
          text: resp.data.message,
          durationMs: 30000,
          type: MessageTypes.error,
        });
      }

      return resp;
    } catch (e) {
      messagesStore.add({
        text: "Неизвестная ошибка, свяжитесь с администратором или попробуйте позже",
        durationMs: 30000,
        type: MessageTypes.error,
      });
    }
  }

  /**
   * Вернуть текущи адрес сервера
   */
  public static getServerUri() {
    if (isDev) {
      return serverUrl;
    }
    return prodUrl;
  }
}
