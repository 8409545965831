import { BasePage } from "../../common/BasePage";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { EventDrawView } from "./EventDrawView";
import { IMoveEvent } from "../../../interfaces/IMoveEvent";


export const InfoPage = () => {
  return (
    <BasePage >
      <h2>О проекте</h2>
      <hr />
      <p>
        Это приложение для поиска мероприятий в краснодаре. Информация о мероприятихя собирается из теграмм каналов города. C помощью
        ИИ алгоритмов  бот пытается определить приглашение на мероприятие,извлечать дату мероприятия, определить тематику. Возможны неточности. 
        По вопросам пишите <a href="https://t.me/zahardjagaev"> сюда</a>.
    </p>
    </BasePage>
  );
};
