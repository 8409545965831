import React, { useContext, useState } from "react";
import { QueryService } from "../../../services/QueryService";
import { EventPreview } from "../../../types/EventPreview";
import { AdminService } from "../../../services/AdminService";
import { observe } from "mobx";
import { observer } from "mobx-react-lite";
import { storeContext } from "../../../store/root-store";

interface IEvent {
  key?: any;
  event: EventPreview;
  className: string;
  onClick: (id: number) => any;
}
const getImgPath = (imgPaths: string) => {
  const paths = imgPaths.split(";");
  return paths[0];
};

enum eventDescState {
  body = "body",
  short_text = "short_text",
}

export const EventComp = observer(({ event, className, onClick }: IEvent) => {
  {
    const { tagsGl } = useContext(storeContext);
    const [currentDescState, setDescState] = useState<eventDescState>(
      eventDescState.short_text
    );

    const changeCurrentDescState = () => {
      if (currentDescState === eventDescState.body) {
        setDescState(eventDescState.short_text);
      } else {
        setDescState(eventDescState.body);
      }
    };

    const getDistance = (eventId: number) => {
      let distance = 0;
      tagsGl.listOfTags.forEach((el) => {
        el.related.forEach((event) => {
          if (event.id === eventId) {
            distance = event.distance;
          }
        });
      });
      return distance.toFixed(4);
    };

    return (
      <div className={className}>
        <div className="card">
          <div style={{ width: 100 + "%", height: 25 + "vh" }}>
            {event.img_path && (
              <img
                width={100 + "%"}
                height={100 + "%"}
                src={
                  QueryService.getServerUri() + "/" + getImgPath(event.img_path)
                }
                className="card-img-top"
                alt="..."
              />
            )}
            {!event.img_path && (
              <img
                width={100 + "%"}
                height={100 + "%"}
                src={"/no-img.svg"}
                className="card-img-top"
                alt="..."
              />
            )}
          </div>
          <div className="card-body">
            <div className="d-flex justify-content-between mt-1 mb-1">
              <div>
                <a
                  href={`https://t.me/${event.source_id
                    ?.split("_")
                    .filter((el, index, ar) => {
                      return index !== ar.length - 1;
                    })
                    .join("_")}/${event?.post_id}`}
                  target="blank"
                >
                  Источник
                </a>
              </div>
              {currentDescState == eventDescState.short_text && (
                <div
                  className="text-button text-button-show-body"
                  onClick={changeCurrentDescState}
                >
                  Показать полный текст
                </div>
              )}
              {currentDescState == eventDescState.body && (
                <div
                  className="text-button text-button-show-short"
                  onClick={changeCurrentDescState}
                >
                  Показать короткий текст
                </div>
              )}
            </div>
            <div>
              {AdminService.getService().isAdmin() && (
                <div>
                  <button
                    className="btn btn-primary"
                    onClick={(e) => onClick(event.id)}
                  >
                    Открыть
                  </button>
                </div>
              )}
            </div>
            <p
              className=""
              style={{
                // height: 25 + "vh",
                overflow: "hidden",
                wordBreak: "break-all",
              }}
            >
              {currentDescState === eventDescState.short_text &&
              event.short_text
                ? event.short_text
                : event.body}
            </p>
          </div>
        </div>
      </div>
    );
  }
});
