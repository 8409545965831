import { makeAutoObservable } from "mobx";
import { QueryService } from "../services/QueryService";

export interface ICheckEventStore {
  id: number;
  body: string;
  create_date: string;
}

export class CheckEvents {
  public data: CheckEvent[] = [];
  constructor() {
    makeAutoObservable(this);
  }
  add(ev: ICheckEventStore) {
    this.data.push(new CheckEvent(ev));
  }

  /**
   * Получить список необработанных новостей
   * @param start
   * @param chunkLength
   */
  public async getListOfEventsForCheck<T>(
    start: number,
    chunkLength: number
  ): Promise<{ data: T[]; hasMore: boolean }> {
    const res = await QueryService.postQuery("/api/events/listForCheck", {
      pagination: {
        start,
        chunkLength,
      },
    });
    return {
      data: res?.data.res as T[],
      hasMore: false,
    };
  }

  /**
   * Загузить все события
   */
  public async loadEvents() {
    const res = await this.getListOfEventsForCheck<ICheckEventStore>(0, 1);
    this.data = [];
    res.data.forEach((el) => {
      this.add(el);
    });
  }
  /**
   *
   * @param eventId
   */
  public async findEventByid(eventId: number) {
    const res = await QueryService.postQuery("/api/events/findById", {});
  }

  /**
   * Обновить необработанную новость
   * @param eventInfo
   */
  public async updateUnprocessedEvent(eventInfo: { [key: string]: any }) {
    await QueryService.postQuery("/api/events/updateUnprocessedEvent", {
      eventInfo,
    });
  }
}
export class CheckEvent {
  public id!: number;
  public text!: string;
  public create_date!: string;
  constructor(ev: ICheckEventStore) {
    makeAutoObservable(this);
    this.id = ev.id;
    this.text = ev.body;
    this.create_date = ev.create_date;
  }
}
export const checkEventsGl = new CheckEvents();
