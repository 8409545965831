import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { QueryService } from "../../../services/QueryService";
import { Loader } from "../../common/Loader";

export const DateManagePanel = observer(({ eventId }: { eventId: number }) => {
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [listOfDates, setListOfDates] = useState<any[]>([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const getCurrentDates = async () => {
    setIsLoaded(false);
    const res = (await QueryService.postQuery(`/api/admin/getDatesByEventId`, {
      id: eventId,
    })) as any;
    //   console.log(res);
    setListOfDates([...(res?.data?.listOfDates as any[])]);
    setIsLoaded(true);
  };

  const deleteEventDate = async (eventDateId: number) => {
    await QueryService.postQuery("/api/admin/deleteDateById", {
      eventDateId,
    });
    await getCurrentDates();
  };

  useEffect(() => {
    console.log("Посылаю запрос - " + eventId);
    //   alert('тут')
    getCurrentDates();
  }, []);
  const onSubmit = async (e: any) => {
    e.preventDefault();
    const res = await QueryService.postQuery(`/api/admin/addDatePeriod`, {
      id: eventId,
      from,
      to,
    });
    await getCurrentDates();
  };
  return (
    <>
      <form className="mb-2 mt-2" onSubmit={onSubmit}>
        <input
          name="from"
          type="date"
          value={from}
          onChange={(e) => setFrom(e.target.value)}
          className="form-control mt-1"
          placeholder="дата от"
        />
        <input
          name="to"
          value={to}
          onChange={(e) => setTo(e.target.value)}
          type="date"
          className="form-control mt-1"
          placeholder="дата по"
        />
        <div className="d-flex justify-content-end mt-2">
          <button className="btn btn-primary">Сохранить</button>
        </div>
      </form>
      {isLoaded && (
        <table className="table table-dark mt-2">
          <thead>
            <tr>
              <th>id</th>
              <th>дата</th>
              <th>удалить</th>
            </tr>
          </thead>
          <tbody>
            {listOfDates.map((dateRow: any) => {
              return (
                <tr key={dateRow?.id}>
                  <td>{dateRow?.id}</td>
                  <td>{dateRow?.event_date}</td>
                  <td>
                    <button
                      className="btn btn-danger"
                      onClick={() => deleteEventDate(dateRow.id)}
                    >
                      удалить
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      {!isLoaded && <Loader />}
    </>
  );
});
