import { observer } from "mobx-react-lite";
import { useContext, useState } from "react";
import { storeContext } from "../../store/root-store";

export const AdminPanel = observer(() => {
  const { settingsGl } = useContext(storeContext);
  const [showOnlyUnique, setShowOnlyUnique] = useState(true);

  const changeUniqueFilterStatus = (e:any) => {
    setShowOnlyUnique(e.target.checked);
    settingsGl.showOnlyUnique = e.target.checked;
  }

  return (
    <div className="d-flex">
      <div>
        <input type="checkbox" onChange={(e) => changeUniqueFilterStatus(e)} checked={showOnlyUnique}  id="show_only_unique" />
        <label htmlFor="show_only_unique">Только уникальные</label>
      </div>
    </div>
  );
});
